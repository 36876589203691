<template>
    <div>
        <ts-page-title
            :title="$t('fixedIncrntiveBonus.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('fixedIncrntiveBonus.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div>
                    <Button type="info" ghost @click="addNew">{{
                        $t("addNew")
                    }}</Button>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('fixedIncrntiveBonus.searchEmployee')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="card_id">
                    {{
                        row.employee_profile ? row.employee_profile.card_id
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="employee_name_en">
                    {{
                        row.employee_profile
                            ? $root.$i18n.locale === "kh"
                                ? row.employee_profile.employee_name_kh
                                : row.employee_profile.employee_name_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="inc_bonus_item">
                    {{
                        row.pay_item_acentive_bonus
                            ? row.pay_item_acentive_bonus.inc_bonus_item
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                width="700px"
                :title="$t('fixedIncrntiveBonus.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from "./form.vue";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    name: "fixed-incentive-bonus",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            width: "width: 300px",
            showForm: false
        };
    },
    computed: {
        ...mapState("payroll/fixedIncentiveBonus", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.payroll.fixedIncentiveBonus.search;
            },
            set(newValue) {
                this.$store.commit(
                    "payroll/fixedIncentiveBonus/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "payroll/fixedIncentiveBonus/RESET_CURRENT_PAGE"
                );
            }
        },

        columns() {
            return [
                {
                    title: this.$t("fixedIncrntiveBonus.cardId"),
                    slot: "card_id"
                },
                {
                    title: this.$t("fixedIncrntiveBonus.employee"),
                    slot: "employee_name_en"
                },
                {
                    title: this.$t("fixedIncrntiveBonus.incentiveBonusName"),
                    slot: "inc_bonus_item"
                },
                {
                    title: this.$t("fixedIncrntiveBonus.incentiveBonusAmount"),
                    key: "inc_bonus",
                    align: "center"
                },
                {
                    title: this.$t("fixedIncrntiveBonus.effectiveDate"),
                    key: "effective_date"
                },
                {
                    title: this.$t("fixedIncrntiveBonus.expiryDate"),
                    key: "expiry_date"
                },
                {
                    title: this.$t("fixedIncrntiveBonus.description"),
                    key: "description",
                    align: "center"
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        addNew() {
            this.showForm = true;
            this.$refs.form_action.fetchResource();
            this.$refs.form_action.clearInput();
        },
        clearEdit() {
            this.showForm = false;
            this.$store.commit("payroll/fixedIncentiveBonus/SET_EDIT_DATA", {});
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("payroll/fixedIncentiveBonus/fetch", attributes)
                .catch(error => {
                    this.loading = false;
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.showForm = true;
            this.$store.commit(
                "payroll/fixedIncentiveBonus/SET_EDIT_DATA",
                record
            );
            this.$refs.form_action.setEditData();
            this.$refs.form_action.fetchResource();
        },
        onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "payroll/fixedIncentiveBonus/destroy",
                    record.record_id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "FIXED INCENTIVE BONUS",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({
                search
            });
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
